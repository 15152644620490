import * as React from 'react'

import { Swiper } from 'swiper/types'
import { motion, AnimatePresence } from 'framer-motion'
import { isMobileDisplay } from '@/utils/devise'
import * as Style from './style.css'
import { register } from 'swiper/element/bundle'

const MOBILE_ANIMATION_SETTING = {
  initial: { y: '100%', opacity: 0 },
  animate: { y: '0%', opacity: 1 },
  exit: { y: '100%', opacity: 0 },
  transition: { duration: 0.5 },
}

// アニメーションなしでも表示のイベントをハンドリングできるように
// 0秒の意味のないイベントを設定
const NO_ANIMATION_SETTING = {
  animate: { z: 0 },
  exit: { z: 0 },
  transition: { duration: 0 },
}

export interface IProps {
  eventPrefix: string
  buttonText: string
  setSwiper: (swiper: Swiper) => void
  showSubmitButton: boolean
  submitHandler: () => void
  children?: React.ReactNode
}

export const SubmitSwiper = ({
  eventPrefix,
  buttonText,
  setSwiper,
  showSubmitButton,
  submitHandler,
  children,
  ...props
}: IProps): JSX.Element => {
  const swiperRef = React.useRef<SwiperRef>(undefined)
  React.useEffect(() => {
    register()

    swiperRef.current.addEventListener(
      eventPrefix + 'slidechange',
      (event: CustomEvent<[swiper: Swiper]>) => {
        setSwiper(event.detail[0])
      },
    )
    swiperRef.current.addEventListener(
      eventPrefix + 'init',
      (event: CustomEvent<[swiper: Swiper]>) => {
        console.log(event)
        setSwiper(event.detail[0])
      },
    )
    swiperRef.current.addEventListener(
      eventPrefix + 'slidechangetransitionend',
      (event: CustomEvent<[swiper: Swiper]>) => {
        setSwiper(event.detail[0])
      },
    )
    swiperRef.current.initialize()
  }, [])

  const animationSetting =
    isMobileDisplay() && showSubmitButton
      ? MOBILE_ANIMATION_SETTING
      : NO_ANIMATION_SETTING

  return (
    <>
      <swiper-container
        init={false}
        nested={true}
        initial-slide={0}
        slides-per-view={1}
        allow-touch-move={false}
        events-prefix={eventPrefix}
        ref={swiperRef}
        style={{ height: '100%' }}
      >
        {children}
      </swiper-container>
      <AnimatePresence>
        {showSubmitButton && (
          <motion.div
            className={Style.FixedSearchButtonContainer}
            {...animationSetting}
          >
            <span className={Style.SearchButton} onClick={submitHandler}>
              {buttonText}
            </span>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
