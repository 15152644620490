import * as React from 'react'

import ChevronLeftIcon from './icon_chevron_left.svg?reactCompornt'
import CheckIcon from './icon_check.svg?reactCompornt'
import * as Style from './style.css'

export type TabType = 'geocode' | 'station'

export interface IProps {
  currentTab: TabType
  currentPrefecture: IPrefecture
  geocodes: IGeocode[]
  stations: IStation[]
  selectedGeocodes: {
    [key: number]: IGeocode[]
  }
  selectedStations: {
    [key: number]: IStation[]
  }
  isLogin?: boolean
  selectTabHandler: (tab: TabType) => void
  selectAllGeocodeHandler: () => void
  deselectAllGeocodeHandler: () => void
  selectGeocodeHandler: (prefectureId: number, geocode: IGeocode) => void
  selectStationHandler: (prefectureId: number, station: IStation) => void
  prevHandler: () => void
}

export const SearchConditionArea = ({
  currentTab,
  currentPrefecture,
  geocodes,
  stations,
  isLogin,
  selectedGeocodes,
  selectedStations,
  selectTabHandler,
  selectAllGeocodeHandler,
  deselectAllGeocodeHandler,
  selectGeocodeHandler,
  selectStationHandler,
  prevHandler,
}: IProps): JSX.Element => {
  const checkedItemSize = selectedGeocodes[currentPrefecture.id]?.length
    ? selectedGeocodes[currentPrefecture.id]?.length
    : 0
  const isALLItemChecked =
    geocodes.length > 0 && checkedItemSize === geocodes.length
  const isItemExists = geocodes.length > 0

  return (
    <div className={Style.SearchConditionArea}>
      <div className={Style.SearchConditionHeaderWrapper}>
        <div className={Style.SearchConditionAreaHeader}>
          {currentPrefecture.name}
          <ChevronLeftIcon
            className={Style.SearchConditionPrefectureHeaderPrevIcon}
            onClick={prevHandler}
          />
          <div
            className={Style.SearchConditionPrefectureHeaderPrevName}
            onClick={prevHandler}
          >
            都道府県
          </div>
        </div>
        <div className={Style.SearchConditionAreaTabs}>
          <label
            className={
              currentTab === 'geocode'
                ? Style.SearchConditionAreaTabActive
                : Style.SearchConditionAreaTab
            }
            onClick={() => selectTabHandler('geocode')}
            htmlFor="SearchConditionAreaTab1"
          >
            市区町村
          </label>
          {isItemExists && stations?.length > 0 ? (
            <label
              className={
                currentTab === 'station'
                  ? Style.SearchConditionAreaTabActive
                  : Style.SearchConditionAreaTab
              }
              onClick={() => selectTabHandler('station')}
              htmlFor="SearchConditionAreaTab2"
            >
              主要駅
            </label>
          ) : (
            <label className={Style.SearchConditionAreaTabDisabled}>
              主要駅
            </label>
          )}
        </div>
      </div>
      {!isItemExists && (
        <p className={Style.SearchConditionAreaItemNotFound}>
          {isLogin && <>該当するサポーターがいません</>}
          {!isLogin && (
            <>
              該当するサポーターがいません。
              <br />
              ログインするとすべてのサポーター情報を見ることができます。
            </>
          )}
        </p>
      )}
      {isItemExists && currentTab === 'geocode' && (
        <ul className={Style.SearchConditionAreaItems}>
          <li>
            <label className={Style.SearchConditionAreaItemAll}>
              <input
                className={Style.SearchConditionAreaItemControl}
                type="checkbox"
                value={currentPrefecture.id}
                checked={isALLItemChecked}
                onChange={() =>
                  isALLItemChecked
                    ? deselectAllGeocodeHandler()
                    : selectAllGeocodeHandler()
                }
              />
              <CheckIcon className={Style.SearchConditionAreaItemCheck} />
              全域
            </label>
          </li>
          {geocodes &&
            geocodes.map((geocode) => (
              <li key={`geocode-${geocode.geocode_id}`}>
                <label className={Style.SearchConditionAreaItem}>
                  <input
                    className={Style.SearchConditionAreaItemControl}
                    type="checkbox"
                    value={geocode.geocode_id}
                    checked={
                      selectedGeocodes[currentPrefecture.id]?.some(
                        (s) => s.geocode_id === geocode.geocode_id,
                      )
                        ? true
                        : false
                    }
                    onChange={() =>
                      selectGeocodeHandler(currentPrefecture.id, geocode)
                    }
                  />
                  <CheckIcon className={Style.SearchConditionAreaItemCheck} />
                  {geocode.name}
                </label>
              </li>
            ))}
        </ul>
      )}
      {isItemExists && currentTab === 'station' && (
        <ul className={Style.SearchConditionAreaItems}>
          {stations &&
            stations.map((station) => (
              <li key={`station-${station.slug}`}>
                <label className={Style.SearchConditionAreaItem}>
                  <input
                    className={Style.SearchConditionAreaItemControl}
                    type="checkbox"
                    value={station.slug}
                    checked={
                      selectedStations[currentPrefecture.id]?.some(
                        (s) => s.slug === station.slug,
                      )
                        ? true
                        : false
                    }
                    onChange={() =>
                      selectStationHandler(currentPrefecture.id, station)
                    }
                  />
                  <CheckIcon className={Style.SearchConditionAreaItemCheck} />
                  {station.name}
                </label>
              </li>
            ))}
        </ul>
      )}
    </div>
  )
}
