import * as React from 'react'

import ChevronLeftIcon from './icon_chevron_left.svg?reactCompornt'
import CircleChevronRightIcon from './icon_circle_chevron_right.svg?reactCompornt'

import * as Style from './style.css'

export interface IProps {
  prefectures: [string, string][]
  selectedGeocodes: {
    [key: number]: IGeocode[]
  }
  selectedStations: {
    [key: number]: IStation[]
  }
  selectPrefectureHandler: (
    prefectureId: number,
    prefectureNmae: string,
  ) => void
  resetHandler: () => void
  prevHandler: () => void
}

const countItems = (
  selectedGeocodes?: IGeocode[],
  selectedStations?: IStation[],
) => {
  const geocodesCount = selectedGeocodes?.length ? selectedGeocodes.length : 0
  const stationsCount = selectedStations?.length ? selectedStations.length : 0
  return geocodesCount + stationsCount
}

export const SearchConditionPrefecture = ({
  prefectures,
  selectedGeocodes,
  selectedStations,
  selectPrefectureHandler,
  resetHandler,
  prevHandler,
}: IProps): JSX.Element => {
  return (
    <div className={Style.SearchConditionPrefecture}>
      <div className={Style.SearchConditionHeaderWrapper}>
        <div className={Style.SearchConditionPrefectureHeader}>
          <ChevronLeftIcon
            className={Style.SearchConditionPrefectureHeaderPrevIcon}
            onClick={prevHandler}
          />
          都道府県を選ぶ
          <span
            className={Style.SearchConditionPrefectureHeaderResetLink}
            onClick={resetHandler}
          >
            すべてをリセット
          </span>
        </div>
      </div>
      <ul className={Style.SearchConditionPrefectureItems}>
        {prefectures.map((prefecture) => (
          <li key={`prefecture-${prefecture[1]}`}>
            <a
              onClick={() =>
                selectPrefectureHandler(
                  Number.parseInt(prefecture[1], 10),
                  prefecture[0],
                )
              }
              className={Style.SearchConditionPrefectureItem}
            >
              {prefecture[0]}
              {countItems(
                selectedGeocodes[Number.parseInt(prefecture[1], 10)],
                selectedStations[Number.parseInt(prefecture[1], 10)],
              ) > 0 && (
                <span className={Style.SearchConditionPrefectureItemCount}>
                  {countItems(
                    selectedGeocodes[Number.parseInt(prefecture[1], 10)],
                    selectedStations[Number.parseInt(prefecture[1], 10)],
                  )}
                </span>
              )}
              <CircleChevronRightIcon
                className={Style.SearchConditionPrefectureItemNextIcon}
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
